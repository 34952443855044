import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class GroupsUsersService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listGroupsUsers (filters) {
		const response = await this.axios.get("groupsUsers/listGroupsUsers", { params: { filters } });
		return response.data;
	}

	async upsertGroupUser (payload) {
		const response = await this.axios.post("groupsUsers/upsertGroupUser", payload);
		return response.data;
	}

	async deleteGroupUser (id) {
		const response = await this.axios.delete("groupsUsers/deleteGroupUser", { params: id });
		return response.data;
	}
}
